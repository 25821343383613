body {
  background-color:  #ECF7FF;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Open Sans', 'Courier New',
    monospace;
}

/* LoaderModal.css */

/* Styling for the modal overlay */
.loader-overlay {
  bottom: 0;
  height: 100%;
  left: 0;
  opacity: 0.9;
  position: fixed;
  top: 83;
  width: 100%;
  z-index: 10;
  backdrop-filter: blur(2px);
  /* Apply a blur effect to the content behind the overlay */
}

.loader-overlay-whitebackground {
  background: #ffffff;
  bottom: 0;
  height: 100%;
  left: 0;
  opacity: 0.9;
  position: fixed;
  top: 83;
  width: 100%;
  z-index: 10;
  backdrop-filter: blur(2px);
  /* Apply a blur effect to the content behind the overlay */
}

/* Styling for the loader */
.loader {
  position: absolute;
  top: 40%;
  left: 45%;
}

div.MuiAlert-icon{
  margin-right: 0;
  padding: 8px 12px 8px 0px
}