.tableContainer{
    position: absolute;
    width: 100%;
    margin-left: 28px;
    margin-right: 29px;
}
    
.table{
    background: #E8ECF1;
}

.tableRow{
    position: absolute;
    top: 290px;
    width: 95% !important;
}

.headcell{
    font-weight: 700 !important;
    font-style: normal !important;
}

.viewButton{
    text-transform: capitalize !important;
    border: 1.5px solid #003057 !important;
    border-radius: 4px !important;
    color: #003057 !important;
    height: 30px;
}

.tableBox{
    height: 100px;
}

.buttonView{
    font-weight: 500;
}

.buttonStyle{
    padding: 10px 20px 0px !important;
}

.wrapper{
    width: 500px !important;
    text-align: center;
}
  
.contentStyle{
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
}

.iconClose{
    float: right;
    cursor: pointer;
    color: #767676;
}

.msgStyle{
    font-size: 12px;
    font-weight: 600;
    margin-top: 3px;
    display: flex;
    justify-content: end;
    margin-right: 18px;
}

@media (max-width:393px) {
    .msgStyle {
        margin-right: 14px;
    }
}