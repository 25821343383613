.buttonStyle{
    padding: 10px 20px 20px;
}

.wrapper{
    width: 450px;
    text-align: center;
    padding: "10px";
}
.contentStyle{
    color: rgba(0, 0, 0, 0.932);
    font-size: 18px;
    font-weight: 300;
}

.iconClose{
    float: right;
    cursor: pointer;
    color: #767676;
}